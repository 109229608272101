
















































import { Component, Vue, Watch, Inject } from "vue-property-decorator";
import { SnackbarOptions } from "@/models/form";
import StatusConfigDialog from "@/components/configuration/status-config-dialog.vue";

import Card from "@/components/material/Card.vue";
import EcSnackBar from "@/components/common/ec-snackbar.vue";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import axios, { CancelTokenSource } from "axios";
import ConfigurationService, {
  AllStatusesConfig,
} from "@/services/configuration-service";
import StatusConfig from "@/components/configuration/status-config-table.vue";
import * as snackbarMessaging from "@/helpers/snackbarMessaging";
import config from "@/config";

const appState = getModule(AppState);

@Component({
  components: {
    Card,
    EcSnackBar,
    StatusConfig,
    StatusConfigDialog,
  },
})
export default class ConfigurationView extends Vue {
  tempDeleteMe = false;

  @Inject() ConfigurationService!: ConfigurationService;

  assignmentFilter: string | null = null;

  showStatusConfigDialog = false;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  loading = true;
  tabIndex = -1;

  cancelToken?: CancelTokenSource;

  statusErrors = {};
  assignmentErrors = {};
  errors: string[] = [];
  configModel: AllStatusesConfig = { statuses: [] };

  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  get apiFault() {
    return appState.apiFault;
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    this.loadPage();
  }

  async mounted() {
    this.configModel =
      await this.ConfigurationService.getStatusMetadataConfig();
  }

  onFailure(event: any) {
    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(event);
  }

  getStatusName(status: number) {
    return config.caseStatus.find((x) => x.value == status)?.name;
  }

  getConfigTemplateByIndex(statusId: number) {
    return this.configModel.statuses.find((x, index) => index === statusId);
  }

  onDialogCancel() {
    this.showStatusConfigDialog = false;
  }

  async loadPage() {
    this.loading = true;

    this.statusErrors = {};

    try {
      if (this.cancelToken) this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();

      this.loading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as any);
        this.loading = false;
      }
    } finally {
    }
  }

  saveConfig() {
    try {
      this.errors = this.getConfigurationErrors();
      if (this.errors.length != 0) {
        snackbarMessaging.setUnsuccesfulMessage("Configuration is invalid");
        return;
      }
      this.ConfigurationService.saveStatusMetadataConfig(this.configModel);
      snackbarMessaging.setSuccessMessage("Configuration Saved");
    } catch (error) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as any);
    }
  }

  getConfigurationErrors(): string[] {
    const result: string[] = [];
    this.configModel.statuses.forEach((status) => {
      status.metadata.forEach((metadataElement, i) => {
        if (!metadataElement.name) {
          result.push(
            `"${this.getStatusName(
              status.id
            )}" element [${i}] "Display Name" is missing`
          );
        }
        if (metadataElement.type == "dropdown") {
          if (metadataElement.values.length == 0) {
            result.push(
              `"${this.getStatusName(status.id)}" "${
                metadataElement.name
              }" has no options`
            );
          }

          metadataElement.values.forEach((metadataValue, index) => {
            if (!metadataValue.value) {
              result.push(
                `"${this.getStatusName(status.id)} ${
                  metadataElement.name
                }" option [${index}] missing "name"`
              );
            }
            if (!metadataValue.displayName) {
              result.push(
                `"${this.getStatusName(status.id)} ${
                  metadataElement.name
                }" option [${index}] missing "display name"`
              );
            }
          });
        }
      });
    });
    return result;
  }
}
