






















































































































import { Component, PropSync, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  StatusMetadata,
  MetadataValue,
} from "@/services/configuration-service";

@Component
export default class StatusConfigRow extends Vue {
  @PropSync("metadata", { default: null }) syncMetadata!: StatusMetadata;

  @Prop({ default: "first", type: Boolean }) first!: boolean;
  @Prop({ default: "last", type: Boolean }) last!: boolean;

  dropdownValues: Array<{ key: string; value: string }> = [];
  optionsDialogOpen = false;

  AvailableTypes() {
    return [{ name: "dropdown" }, { name: "freetext" }];
  }

  shouldDisplayCommaSeparatedList() {
    return this.syncMetadata.type === "dropdown";
  }

  addMetadataOption() {
    const newValue: MetadataValue = {
      displayName: "",
      value: "",
    };
    this.syncMetadata.values.push(newValue);
  }

  deleteMetadataValue(model: MetadataValue) {
    const index = this.syncMetadata.values.indexOf(model);
    this.syncMetadata.values.splice(index, 1);
  }
}
